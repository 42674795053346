// material-ui
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Privacy PAGE ||============================== //


export default function HomeAlimGastroLocale() {

    return (
      <>
        <Helmet>
          <title>Alimentation et Gastronomie Locale | Boutigou</title>
          <meta name="description" content="Explorez les saveurs de la gastronomie locale avec Boutigou. Découvrez des recettes, des produits de saison et comment soutenir les producteurs locaux." />
        </Helmet>
        <MainCard title="L&apos;Alimentation et la Gastronomie Locale, kesako ?" sx={{ mb: 4 }}>
          <Grid container spacing={gridSpacing} alignItems="stretch" direction="row">
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    3 astuces pour réussir vos confitures maison
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/confitures-maison-guide-complet">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    5 raisons d&apos;adorer les marchés locaux
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/marches-locaux">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    10 Plats Locaux à Découvrir
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/10-plats-locaux-a-decouvrir">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h2" component="div">
                    Top 5 légumes d&apos;automne à savourer
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="/kesako/vracs/top-5-legumes-automne">En savoir plus</Button>
                </CardActions>
              </Card>
            </Grid>
            
          </Grid>
        </MainCard>
      </>
  );
}